<template>
  <div class="page">
    <Navbar :title="$t('main.my')" />
    <div class="header">
      <div class="user">
        <img class="user_avater" :src="user.avater" />
        <div class="user_box">
          <div class="user_box_name">{{ $store.state.userInfo.username }}</div>
          <!-- <div class="user_box_text">平台币: <span>{{user.coin}}</span></div> -->
        </div>
        <!-- <div @click="signIn()" class="user_signIn" :class="{'user_signIn_dis': user.sign == 1}">{{user.sign == 1 ? '已签到' : '签到'}}<span v-if="user.sign == 0">福利</span></div> -->
      </div>
      <!-- <div class="banner"> <Banner :list="banners"/> </div> -->
    </div>
    <!-- <div class="border"></div>
    <div @click="goUserInfo()" class="cell">
      <div class="cell_img"> <img src="@/assets/img/my/grxx.png" alt=""> </div>
      <div class="cell_text">个人信息</div>
      <SvgIcon name="arrow-left" class="cell_arrow" color="#8d8d8d" />
    </div> -->
    <!-- <div class="border"></div>
    <div @click="goNapOrder()" class="cell">
      <div class="cell_img"> <img src="@/assets/img/my/czjl.png" alt=""> </div>
      <div class="cell_text">充值记录</div>
      <SvgIcon name="arrow-left" class="cell_arrow" color="#8d8d8d" />
    </div> -->
    <div class="border"></div>
    <div @click="goSetAccount()" class="cell" v-if="showGuest">
      <div class="cell_img"><img src="@/assets/img/my/xgmm.png" alt="" /></div>
      <div class="cell_text">{{ $t('login.bind') }}</div>
      <SvgIcon name="arrow-left" class="cell_arrow" color="#8d8d8d" />
    </div>
    <div @click="goSetPassword()" class="cell" v-if="!showGuest">
      <div class="cell_img"><img src="@/assets/img/my/xgmm.png" alt="" /></div>
      <div class="cell_text">{{ $t("login.changePassword") }}</div>
      <SvgIcon name="arrow-left" class="cell_arrow" color="#8d8d8d" />
    </div>
    <div @click="goGate()" class="cell">
      <div class="cell_img"><img src="@/assets/img/my/gate.png" alt="" /></div>
      <div class="cell_text">Nạp Coin</div>
      <SvgIcon name="arrow-left" class="cell_arrow" color="#8d8d8d" />
    </div>
    <div @click="goInstall()" class="cell">
      <div class="cell_img">
        <img src="@/assets/img/my/install.png" alt="" />
      </div>
      <div class="cell_text">Hướng dẫn cài đặt</div>
      <SvgIcon name="arrow-left" class="cell_arrow" color="#8d8d8d" />
    </div>
    <!-- <div @click="goNap()" class="cell">
      <div class="cell_img"><img src="@/assets/img/my/pay.png" alt="" /></div>
      <div class="cell_text">Nap</div>
      <SvgIcon name="arrow-left" class="cell_arrow" color="#8d8d8d" />
    </div> -->
    <!-- <div @click="goCS()" class="cell">
      <div class="cell_img"> <img src="@/assets/img/my/wdkf.png" alt=""> </div>
      <div class="cell_text">我的客服</div>
      <SvgIcon name="arrow-left" class="cell_arrow" color="#8d8d8d" />
    </div>
    <div @click="goUser()" class="cell">
      <div class="cell_img"> <img src="@/assets/img/my/yhxy.png" alt=""> </div>
      <div class="cell_text">用户协议</div>
      <SvgIcon name="arrow-left" class="cell_arrow" color="#8d8d8d" />
    </div> -->
    <div
      @click="openCS = false"
      class="popup popup_bottom"
      :class="{ popup_bottom_on: openCS }"
    >
      <div @click.stop class="popup_box">
        <div class="popup_content CS_box">
          <div class="CS_title">我的客服</div>
          <div @click="openCS = false" class="CS_close">
            <SvgIcon name="close" color="#8d8d8d" />
          </div>
          <div class="CS_cell">
            <div class="CS_fb_svg"><SvgIcon name="fb" color="#ffffff" /></div>
            <div @click="goFb()" class="CS_fb_btn btn_primary">点击进入</div>
          </div>
          <div class="CS_cell">
            <div class="CS_kf_img"><SvgIcon name="kefu" color="#8d8d8d" /></div>
            <div class="CS_kf_box">
              <div class="CS_kf_email">{{ CSEmail }}</div>
              <div @click="goCopy()" class="CS_kf_btn btn_primary">复制</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Banner from "@/components/banner";
export default {
  data() {
    return {
      user: {
        avater: require("@/assets/img/avater/1.png"),
        name: "小小",
        coin: 0,
        sign: 0, // 签到 0 未签 1 已签
      },
      banners: [
        {
          img: "https://img3.muugame.com/images/banner-1675388115.png",
          type: 1,
          path: "",
        },
        {
          img: "https://img3.muugame.com/images/banner-1643005273.jpg",
          type: 1,
          path: "",
        },
        {
          img: "https://img3.muugame.com/images/banner-1668499645.jpg",
          type: 1,
          path: "",
        },
      ],
      openCS: false, // 打开客服
      CSEmail: "muugame@Gmail",
    };
  },
  computed: {
    showGuest() {
      let guestInfo = JSON.parse(localStorage.getItem("guestInfo"));
      return guestInfo
    },
  },
  components: { Banner },
  created() {
    // this.$store.state.sdkShow = false
    // this.$store.commit("openSdk", false);
  },
  methods: {
    signIn() {
      // 签到
      this.user.sign = 1;
    },
    goUserInfo() {
      this.$router.push("my/userInfo");
    },
    goNapOrder() {
      this.$router.push("my/napOrder");
    },
    goSetPassword() {
      this.$router.push("my/setPassword");
    },
    goSetAccount() {
      this.$router.push("my/account");
    },
    goGate() {
      this.$router.push("my/gate");
    },
    goInstall() {
      this.$router.push("my/install");
    },
    goNap() {
      const CryptoJS = require("crypto-js");
      let host = "https://pay.mgol.vn/login/autoLogin";
      let game_id = localStorage.getItem("game_id");
      let userid = CryptoJS.MD5(localStorage.getItem("userid")).toString();
      let token = btoa(localStorage.getItem("token"));
      let url = `${host}?game_id=${game_id}&userid=${userid}&token=${token}`;
      window.open(url);
    },
    goCS() {
      this.openCS = true;
    },
    goUser() {
      this.$router.push("my/userAgreement");
    },
    goFb() {
      window.open("https://www.facebook.com/");
    },
    goCopy() {
      this.$utils.copyHandle(this.CSEmail);
      this.$Msg("复制成功");
    },
  },
};
</script>
<style scoped lang="scss">
.page {
  display: block;
  padding-bottom: 1rem;
}
.header {
  padding: 0.4rem 0.3rem 0.4rem 0.2rem;
  box-sizing: border-box;
}
.border {
  width: 100%;
  height: 0.14rem;
  background-color: #f0f0f0;
}
.user {
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
  width: 100%;
}
.user_avater {
  margin-right: 0.2rem;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  border: 1px solid $color-primary;
}
.user_box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  // height: .7rem;
  width: 4.5rem;
  word-wrap: break-word;
}
.user_box_name {
  font-size: 0.28rem;
  font-weight: bold;
}
.user_box_text {
  font-size: 0.24rem;
  color: #999;
}
.user_box_text span {
  color: $color-primary;
  font-weight: bold;
}
.user_signIn {
  position: relative;
  padding: 0.03rem 0.26rem;
  border: 1px solid $color-primary;
  color: $color-primary;
  font-size: 0.24rem;
  border-radius: 0.3rem;
}
.user_signIn span {
  position: absolute;
  top: -0.3rem;
  right: -0.1rem;
  padding: 0 0.1rem;
  line-height: 0.3rem;
  text-align: center;
  font-size: 0.2rem;
  color: #fff;
  background-color: #f25200;
  border-radius: 0.3rem 0.3rem 0.3rem 0;
}
.user_signIn_dis {
  color: #8d8d8d;
  border-color: #8d8d8d;
}
.cell {
  display: flex;
  align-items: center;
  padding: 0 0.2rem;
  width: 100%;
  height: 1rem;
  box-sizing: border-box;
  font-size: 0.25rem;
  color: #8d8d8d;
  cursor: pointer;
}
.cell_img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.2rem;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #e1e1e1;
  border-radius: 0.06rem;
  overflow: hidden;
  box-sizing: border-box;
}
.cell_img img {
  width: 70%;
}
.cell_text {
  flex: 1;
}
.cell_arrow {
  width: 0.35rem;
  height: 0.35rem;
  transform: rotateZ(180deg);
}
.banner {
  width: 100%;
  height: 2rem;
  border-radius: 0.2rem;
  overflow: hidden;
}
.CS_box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0.6rem;
  width: 100%;
  // border-radius: 0 .3rem .3rem 0;
}
.CS_title {
  margin-bottom: 0.1rem;
  line-height: 1rem;
}
.CS_close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.12rem;
}
.CS_close svg {
  width: 0.5rem;
  height: 0.5rem;
}
.CS_cell {
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
  width: 90%;
  overflow: hidden;
}
.CS_fb_svg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  background-color: #007cf2;
  overflow: hidden;
}
.CS_fb_svg svg {
  width: 0.5rem;
  height: 0.5rem;
}
.CS_fb_btn {
  margin-left: 0.2rem;
  flex: 1;
  line-height: 0.7rem;
  font-size: 0.25rem;
}
.CS_kf_img svg {
  width: 0.7rem;
  height: 0.7rem;
}
.CS_kf_box {
  display: flex;
  align-items: center;
  flex: 1;
  margin-left: 0.2rem;
  background-color: #eeeeee;
  border-radius: 1rem;
}
.CS_kf_email {
  flex: 1;
  text-align: center;
  font-size: 0.22rem;
}
.CS_kf_btn {
  width: 1.5rem;
  line-height: 0.7rem;
  font-size: 0.25rem;
}
</style>